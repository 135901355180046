import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { StyledSection, StyledButton } from '../styles'
import { GiFlyingFlag } from 'react-icons/gi'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.subprimary};
`

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 2rem 0;

  .image {
    margin: auto;
    max-width: 1170px;
  }

  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.backgroundcolor};
  }

  small {
    margin: 0 auto;
    color: ${({ theme }) => theme.primary};
    max-width: 50rem;
    font-size: 1rem;
  }

  p {
    color: ${({ theme }) => theme.backgroundcolor};
  }

  .seperator {
    flex-flow: row nowrap;
    align-items: center;
    display: flex;

    .leftLine {
      border-top: 1px solid ${({ theme }) => theme.primary};
      display: block;
      height: 1px;
      position: relative;
      top: 1px;
      width: 100%;
    }

    .rowLogo {
      font-size: 4rem;
      color: ${({ theme }) => theme.primary};
      padding: 0 2rem;
    }

    .rightLine {
      border-top: 1px solid ${({ theme }) => theme.primary};
      display: block;
      height: 1px;
      position: relative;
      top: 1px;
      width: 100%;
    }
  }
`

const StyledContent = styled.div`
  display: grid;
  grid-template-columns:
    minmax(1.2rem, 1fr)
    minmax(auto, 57ch)
    minmax(1.2rem, 1fr);

  color: white;
  background-color: ${({ theme }) => theme.subprimary};

  div {
    padding: 3rem 0;
    grid-column: 2;

    h2 {
      margin: 0;
      padding-top: 2rem;
    }

    h3 {
      margin: 0;
      padding-bottom: 1rem;
    }

    .all-stars {
      padding-top: 3rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
    }
    p {
      padding: 1rem 0 1rem 0;
    }

    ul {
      padding: 1rem 0 0 2rem;
    }

    small {
      display: flex;
      padding-top: 3rem;
    }
  }
`

const Membership = () => {
  const MembershipData = useStaticQuery(graphql`
    query golfBall {
      file(name: { eq: "home_1_bg_1" }) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournament_thank_you {
            header
            excerpt
            previousWinner
          }
        }
      }
    }
  `)

  const {
    file: {
      childImageSharp: { fluid },
    },
    wpgraphql: {
      pageBy: {
        tournament_thank_you: { header, excerpt, previousWinner },
      },
    },
  } = MembershipData

  return (
    <StyledWrapper>
      <StyledSection>
        <StyledContainer>
          <StyledContent>
            <div>
              <h2>{header}</h2>
              <small>{excerpt}</small>
              <div className="seperator">
                <span className="leftLine"></span>
                <div className="rowLogo">
                  <GiFlyingFlag />
                </div>
                <span className="rightLine"></span>
              </div>
              <p>{previousWinner}</p>
              <div className="buttons">
                <Link to="/sponsorship">
                  <StyledButton green>Sponsorship</StyledButton>
                </Link>
                <Link to="/registration">
                  <StyledButton green>Registration</StyledButton>
                </Link>
              </div>
              <div className="center">
                <div className="image">
                  <Img fluid={fluid} />
                </div>
              </div>
            </div>
          </StyledContent>
        </StyledContainer>
      </StyledSection>
    </StyledWrapper>
  )
}

export default Membership
