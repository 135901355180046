import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.subprimary};

  div {
    padding: 3rem 0;
    grid-column: 2;
    max-width: 57ch;

    h2 {
      margin: 0;
      padding-top: 2rem;
    }

    h3 {
      margin: 0;
      padding-bottom: 1rem;
    }

    .all-stars {
      padding-top: 3rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
    }
    p {
      padding: 1rem 0 1rem 0;
    }

    ul {
      padding: 1rem 0 0 2rem;
    }

    small {
      display: flex;
      padding-top: 3rem;
    }
  }

  strong {
    font-size: 2rem;
  }
`

const HistoryBlurb = () => {
  const HistoryData = useStaticQuery(graphql`
    query history {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournamentHistory {
            tournamentHistory
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        tournamentHistory: { tournamentHistory },
      },
    },
  } = HistoryData

  return (
    <StyledContent>
      <div dangerouslySetInnerHTML={{ __html: tournamentHistory }} />
    </StyledContent>
  )
}

export default HistoryBlurb
