import React from 'react'
import Layout from '../layouts/siteLayout'
import Landing2 from '../components/landing2'
import Membership from '../components/membership'
import Gallery from '../components/gallery'
import HistoryBlurb from '../components/historyBlurb'

const HistoryPage = () => {
  return (
    <Layout>
      <Landing2 header="History" />
      <Membership />
      <Gallery />
      <HistoryBlurb />
    </Layout>
  )
}

export default HistoryPage
