import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image7 = () => {
  const data = useStaticQuery(graphql`
    query Image7 {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          gallery {
            image7 {
              imageFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1920, quality: 100) {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        gallery: {
          image7: {
            imageFile: {
              childImageSharp: { fluid },
            },
          },
        },
      },
    },
  } = data
  return <Img fluid={fluid} />
}

export default Image7
