import React from 'react'
import styled from 'styled-components'
import Image1 from './image-1'
import Image2 from './image-2'
import Image3 from './image-3'
import Image4 from './image-4'
import Image5 from './image-5'
import Image6 from './image-6'
import Image7 from './image-7'

const StyledGallery = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);

  div {
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  /* Regular */

  .image1 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .image2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .image3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .image4 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .image5 {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .image6 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .image1 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .image2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .image3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .image4 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .image5 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    .image6 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    .image7 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 5;
      grid-row-end: 6;
    }
  }
`

const Gallery = () => {
  return (
    <>
      <StyledGallery>
        <div className="image1">
          <Image1 />
        </div>
        <div className="image2">
          <Image2 />
        </div>
        <div className="image3">
          <Image3 />
        </div>
        <div className="image4">
          <Image4 />
        </div>
        <div className="image5">
          <Image5 />
        </div>
        <div className="image6">
          <Image6 />
        </div>
        <div className="image7">
          <Image7 />
        </div>
      </StyledGallery>
    </>
  )
}

export default Gallery
